<template>
  <el-select :value="valueLabel"
             multiple
             :disabled="disabled"
             @remove-tag="clearHandle">
    <el-option :key="props.value"
               :value="valueTitle"
               :label="valueTitle">
      <el-tree @check-change="checkChange"
               id="tree-option"
               ref="selectTree"
               :accordion="accordion"
               :data="options"
               show-checkbox
               :props="props"
               :node-key="props.value"
               :default-expanded-keys="defaultExpandedKey"
               @node-click="handleNodeClick" />
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: 'SelectTree',
  props: {
    /* 配置项 */
    props: {
      type: Object,
      default: () => ({
        value: 'id', // ID字段名
        label: 'title', // 显示名称
        children: 'children', // 子级字段名
      }),
    },
    /* 选项列表数据(树形结构的对象数组) */
    options: {
      type: Array,
      default: () => [],
    },
    datas: {
      type: Array,
      default: () => [],
    },
    /* 初始值 */
    value: {
      type: Array,
      default: () => [],
    },
    /* 可清空选项 */
    clearable: {
      type: Boolean,
      default: () => true,
    },
    /* 自动收起 */
    accordion: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  data () {
    return {
      valueId: this.value, // 初始值
      valueLabel: [],
      valueTitle: '',
      defaultExpandedKey: [],
    }
  },
  mounted () {
    this.initHandle()
  },
  methods: {
    checkChange (data) {
      const checkedNodes = this.$refs.selectTree.getCheckedNodes()
      this.valueId = []
      this.valueLabel = []
      checkedNodes.forEach(c => {
        this.valueId.push(c.id)
        this.valueLabel.push(c[this.props.label])
      })
      this.$emit('update:value', this.valueId)
    },
    // 初始化值
    initHandle () {
      // 这里要延迟执行，否则有BUG
      setTimeout(() => {
        if (this.value.length > 0) {
          this.value.forEach(val => {
            const treeLabel = this.$refs.selectTree.getNode(val).data[this.props.label]
            this.valueLabel.push(treeLabel)
            this.$refs.selectTree.setChecked(val, true)
            this.defaultExpandedKey = [val]
          })
        } else {
          // this.valueTitle = null // 初始化显示
          // this.$refs.selectTree.setCurrentKey(null) // 设置默认选中
        }
      }, 1000)
      this.$nextTick(() => {
        const scrollWrap = document.querySelectorAll('.el-scrollbar .el-select-dropdown__wrap')[0]
        const scrollBar = document.querySelectorAll('.el-scrollbar .el-scrollbar__bar')
        scrollWrap.style.cssText = 'margin: 0px; max-height: none; overflow: hidden;'
        scrollBar.forEach(ele => ele.style.width = 0)
      })
    },
    // 切换选项
    handleNodeClick (node) {
      // this.valueTitle = node[this.props.label]
      // if (this.valueId.indexOf(node[this.props.value]) === -1) {
      //   this.valueId.push(node[this.props.value])
      //   this.valueLabel.push(node[this.props.label])
      // }
      // console.log(this.valueId)
      // // this.$emit('getValue',this.valueId)
      // this.$emit('input', this.valueId)
      // this.$emit('update:value', this.valueId)
      // this.defaultExpandedKey = []
    },
    // 清除选中
    clearHandle (val) {
      // this.valueId = []
      const checkedNodes = this.$refs.selectTree.getCheckedNodes()
      checkedNodes.forEach(d => {
        if (d[this.props.label] === val) {
          // this.valueId.push(d.id)
          this.$refs.selectTree.setChecked(d.id, false)
        }
      })
      // const indexNum = this.valueLabel.indexOf(val)
      // this.valueLabel.splice(indexNum, 1)
      // this.$emit('update:value', this.valueId)
      // this.defaultExpandedKey = []
      // this.clearSelected()
    },
    /* 清空选中样式 */
    clearSelected () {
      const allNode = document.querySelectorAll('#tree-option .el-tree-node')
      allNode.forEach(element => element.classList.remove('is-current'))
    },
  },
}
</script>

<style scoped>
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 0;
  overflow: hidden;
  overflow-y: auto;
}
.el-select-dropdown__item.selected {
  font-weight: normal;
}
ul li >>> .el-tree .el-tree-node__content {
  height: auto;
  padding: 0 20px;
}
.el-tree-node__label {
  font-weight: normal;
}
.el-tree >>> .is-current .el-tree-node__label {
  color: #409eff;
  font-weight: 700;
}
.el-tree >>> .is-current .el-tree-node__children .el-tree-node__label {
  color: #606266;
  font-weight: normal;
}
</style>
